.MatchesTeaser-module__i6Q_-q__matches-teaser {
  width: 100%;
  color: var(--text-primary);
  background-color: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__main {
  border: 1px solid var(--line-line);
  background-color: var(--bg-block);
  border-radius: 5px;
  overflow: hidden;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__top {
  align-items: center;
  gap: 10px;
  display: flex;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__tabs {
  align-items: center;
  display: flex;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__tab {
  color: var(--text-secondary);
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__tab:hover {
  color: var(--text-primary);
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__wrapper {
  flex-direction: row;
  width: 100%;
  display: flex;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__swiper {
  width: 100%;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__slides:last-child {
  border-bottom: 0;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__slides:last-child a {
  border-right: 0;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__button {
  margin: 10px;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__nav-button {
  color: var(--text-secondary);
  display: none;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__nav-button--prev, .MatchesTeaser-module__i6Q_-q__matches-teaser__nav-button--next {
  border: 1px solid var(--line-line);
  box-sizing: content-box;
  fill: var(--icons-primary);
  border-radius: 5px;
  padding: 5px;
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__nav-button--prev {
  transform: rotate(90deg);
}

.MatchesTeaser-module__i6Q_-q__matches-teaser__nav-button--next {
  transform: rotate(270deg);
}

@media (max-width: 660px) {
  .MatchesTeaser-module__i6Q_-q__matches-teaser {
    border-radius: 5px;
    padding: 10px;
  }

  .MatchesTeaser-module__i6Q_-q__matches-teaser__navigation-btn-wrapper {
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  .MatchesTeaser-module__i6Q_-q__matches-teaser__button {
    width: 50%;
    margin: 10px 10px 10px 0;
  }

  .MatchesTeaser-module__i6Q_-q__matches-teaser__nav-button {
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser {
  border-bottom: 1px solid var(--line-line);
  border-right: 1px solid var(--line-line);
  color: inherit;
  display: flex;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 10px;
  min-height: 50px;
  display: flex;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__score, .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__date {
  justify-content: center;
  align-items: center;
  display: flex;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__score {
  color: var(--text-primary);
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__date {
  color: var(--text-secondary);
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team {
  flex: 1;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  display: flex;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team--home {
  justify-content: flex-end;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team--home .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team-name {
  text-align: end;
  width: 100%;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team--away {
  justify-content: flex-start;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team--away .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team-name {
  text-align: start;
  width: 100%;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team-name {
  color: var(--text-primary);
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__caption-text {
  color: var(--text-secondary);
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__caption-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__current-minute {
  color: var(--text-error-live);
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__penalty {
  color: var(--text-secondary);
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__match {
  border-right: 0;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__match:last-child {
  border-bottom: 0;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__info {
  text-align: center;
  width: 100%;
  max-width: 70px;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__avatar {
  flex-shrink: 0;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__avatar-wrapper {
  width: 24px !important;
  height: 24px !important;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__avatar-wrapper div {
  width: 24px;
}

.MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__avatar-wrapper div span {
  width: 24px;
  height: 24px;
}

@media (max-width: 660px) {
  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser {
    border-right: none;
    justify-content: center;
    width: calc(100vw - 22px);
    display: flex;
  }

  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__wrapper {
    grid-template-columns: 140px 53px 140px;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__info {
    text-overflow: ellipsis;
    width: 70px;
  }

  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team {
    gap: 10px;
  }

  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team__text {
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 75px;
    min-width: 70px;
    max-width: 90px;
    display: -webkit-box;
    overflow: hidden;
  }

  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__match {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .MatchMiniatureTeaser-module__H460MG__match-miniature-teaser__team__text {
    overflow-wrap: break-word;
    -webkit-line-clamp: 1;
    width: 45px;
    min-width: 45px;
    max-width: 90px;
    overflow: hidden;
  }
}

